export default {
  apiKey: "AIzaSyDEXpzK23HawMW0IXeNl_VzANO8wK0WGwY",
  authDomain: "bodrumtours-meibo.firebaseapp.com",
  databaseURL: "https://bodrumtours-meibo.firebaseio.com",
  projectId: "bodrumtours-meibo",
  storageBucket: "bodrumtours-meibo.appspot.com",
  messagingSenderId: "234846361006",
  appId: "1:234846361006:web:725f9cb71311fec307243f",
  measurementId: "G-9X2115YP1B",
};
