// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/products";
export const SINGLE_POST_PAGE = "/product";
export const SINGLE_BOAT_PAGE = "/boat";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "/edit";
export const AGENT_IMAGE_EDIT_PAGE = "/change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "/change-password";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";
export const AGENT_PROFILE_CONTACT = "/contact";

// Other Pages
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const PRIVACY_PAGE = "/privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";

export const FIREBASE_SETTINGS = "lovebodrum";
export const FIREBASE_PRODUCTS = "lovebodrum_tours";
export const FIREBASE_ORDERS = "lovebodrum_orders";
export const FIREBASE_CUSTOMERS = "lovebodrum_customers";

export const QNB_MERCHANTID = "095700000020438";
export const QNB_USERCODE = "gumbetbodrumapi";
export const QNB_USERPASS = "32TfW";

export const SITE_NAME = "Bodrum Tours";

//095700000020362
//zaryatcilikadmin
//iKA1t
//bodrum.boats
//zarapi
//R1lvj

//095700000020412
//zaryatcilikadmin2
//7BgRc
//bodrumwatersports.com
//toursofbodrumapi2
//R39yc

//095700000020420
//zaradmin11
//0jq8e
//gumbetwatersports.com
//gumbetwatersportsapi
//Lr0Ed

//095700000020446
//zaryatcilikadmin5
//i5Jjs
//toursofbodrum.com
//toursofbodrumapi
//YQ6hd

//095700000020438
//zaryatcilikadmin1
//pZ65T
//bodrum.tours
//gumbetbodrumapi
//32TfW
