import React from "react";
import PropTypes from "prop-types";
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
  FooterContainer,
} from "./Footer.style";

const Footer = ({ logo, tursab, menu, bgSrc, copyright, className, path }) => {
  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        <FooterContainer>{logo && logo}</FooterContainer>

        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
        <a href="https://zar.com.tr" target="_blank" rel="noopener noreferrer">
          Zar Yachting Travel Tursab No: 13324
        </a>
        {tursab && tursab}
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
