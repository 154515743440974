import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const AuthContext = React.createContext();

const fakeUserData = {
  id: 1,
  name: "Jhon Doe",
  avatar:
    "http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png",
  roles: ["USER", "ADMIN"],
};

const AuthProvider = (props) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [iyzicoPayment, setIyzicoPayment] = useState({
    locale: "EN",
    conversationId: uuidv4(),
    price: "1",
    paidPrice: "1.2",
    currency: "TRY",
    installment: "1",
    basketId: uuidv4(),
    paymentChannel: "MOBILE",
    paymentGroup: "OTHER",
    paymentCard: {
      cardHolderName: "John Doe",
      cardNumber: "5528790000000008",
      expireMonth: "12",
      expireYear: "2030",
      cvc: "123",
      registerCard: "0",
    },
    buyer: {
      id: "userid",
      name: "John",
      surname: "Doe",
      //gsmNumber: "+905350000000",
      email: "email@email.com",
      identityNumber: "74300864791",
      registrationAddress: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
      ip: "85.34.78.112",
      city: "Muğla",
      country: "Turkey",
      zipCode: "48400",
    },
    // shippingAddress: {
    //   contactName: "Jane Doe",
    //   city: "Istanbul",
    //   country: "Turkey",
    //   address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
    //   zipCode: "34742",
    // },
    billingAddress: {
      contactName: "Jane Doe",
      city: "Muğla",
      country: "Turkey",
      address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
      zipCode: "34742",
    },
    basketItems: [
      {
        id: "BI101",
        name: "Binocular",
        category1: "Collectibles",
        category2: "Accessories",
        itemType: "VIRTUAL",
        price: "0.3",
      },
    ],
  });

  const signIn = (params) => {
    console.log(params, "sign in form Props");
    setUser(fakeUserData);
    setLoggedIn(true);
    history.push(`/`);
  };

  const signUp = (params) => {
    console.log(params, "sign up form Props");
    setUser(fakeUserData);
    setLoggedIn(true);
    history.push(`/`);
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
        iyzicoPayment,
        setIyzicoPayment,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
